export class ChatPreview {
  constructor(
    public chatId: string,
    public venueName: string,
    public activeRecipientId: string,
    public status: "automatic" | "require takeover" | "takenover" | "closed",
    public customerName: string,
    public imageUrl: string,
    public lastMessageId: string,
    public lastMessageBody: string,
    public lastMessageDate: Date | number | string,
    public unreadMessages: number
  ) {}
}

export class MessageAttachment {
  constructor(
    public type: "image" | "audio" | "video" | "file" | "template",
    public payload: any
  ) {}
}

export class ChatMessage {
  constructor(
    public id: string,
    public venueName: string,
    public customerId: string,
    public sender: User,
    public receiver: User,
    public fromCustomer: boolean,
    public body: string,
    public attachment: MessageAttachment,
    public status: "pending" | "stored" | "sent" | "read" | "unsent" = "sent",
    public date: Date | number | string,
    // relative session dynamic information (any later change must not be affected by these stting on existing messages)
    public prompt: string,
    public sessionStatus?:
      | "automatic"
      | "require takeover"
      | "takenover"
      | "closed",
    public activeRecipientId?: string
  ) {}
}

export class ChatRoom {
  constructor(
    public id: string,
    public venueName: string,
    public prompt: string,
    public activeRecipientId: string,
    public status: "automatic" | "require takeover" | "takenover" | "closed",
    public recipients: Recipient[],
    public customer: Customer,
    public messages: ChatMessage[]
  ) {}
}

export class Recipient implements User {
  constructor(
    public id: string,
    public name: string,
    public type: "admin" | "superAdmin" | "recipient" | "bot",
    // TODO: Review the need for an image (put default image)
    public imageUrl?: string
  ) {}
}

export class Customer implements User {
  constructor(
    public id: string,
    public venueName: string,
    // TODO: Put default name if not found
    public name: string,
    // TODO: Review the need for an image (put default image) if not found
    public imageUrl?: string
  ) {}
}

export interface User {
  id: string;
  name: string;
  imageUrl?: string;
}
