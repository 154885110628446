import { Injectable, NgZone } from '@angular/core';
import {
  CanLoad,
  UrlSegment,
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { Route } from '@angular/compiler/src/core';
import { AuthService } from '../../services/auth/auth.service';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotAuthGuardLoad implements CanLoad {
  constructor(private _authServ: AuthService, private _router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    return this._authServ.isLoggedIn().pipe(
      take(1),
      map(isLoggedIn => {
        return !isLoggedIn;
      })
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class NotAuthGuardAct implements CanActivate {
  constructor(private _authServ: AuthService, private _router: Router, private _ngZone: NgZone) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this._authServ.isLoggedIn().pipe(
      take(1),
      map(isLoggedIn => {
        if (isLoggedIn) {
          // redirect to main page
          this._ngZone.run(() => this._router.navigateByUrl('/', { replaceUrl: true }));
        }
        return !isLoggedIn;
      })
    );
  }
}
