import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { tap, catchError } from 'rxjs/operators';
/* Jwt helper */
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

const jwtHelper = new JwtHelperService();

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private _authServ: AuthService, private _router: Router) {
    console.log('auth interecept');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // set token
    const reqClone = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this._authServ.accessToken}`
      }
    });
    console.log('request:\n', req);
    return next.handle(reqClone).pipe(
      tap(event => {
        if (this._authServ.accessToken && jwtHelper.isTokenExpired(this._authServ.accessToken)) {
          this._authServ.logout();
        }
      }), catchError((err: HttpErrorResponse) => {
        /* console.error(err); */
        if (err instanceof HttpErrorResponse) {
          if ((err.status === 498 || (err.status === 401
            && this._authServ.accessToken
            && jwtHelper.isTokenExpired(this._authServ.accessToken)))) { // token-expired | not-authorized error
            this._authServ.logout();
          }
          // if (err.status === 403) { // forbidden error
          //   // navigate to main page
          //   this._router.navigateByUrl('/');
          // }
        }
        return throwError(err);
      }
      ));

  }
}
