import { HttpClient } from '@angular/common/http';
import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Badge } from '@ionic-native/badge/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { switchMap } from 'rxjs/operators';

import { API } from '../environments/environment';
import { AuthService } from './services/auth/auth.service';
import { BrowserNotificationsService } from './services/browser-notifications/browser-notifications.service';
import { PlatformService } from './services/platform/platform.service';
import { SocketService } from './services/socket/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private _badge: Badge,
    private fcm: FCM,
    private _http: HttpClient,
    private _authServ: AuthService,
    private _socketServ: SocketService,
    private _platformServ: PlatformService,
    private _router: Router,
    private _ngZone: NgZone
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(readySource => {
      this._platformServ.info.isCordova = readySource && readySource.toLowerCase() !== 'dom';
      this._platformServ.info.width = screen.width;
      // watch for orientation change
      window &&
        window.addEventListener('orientationchange', () => {
          this._platformServ.info.width = screen.width;
          if (this._router.url.endsWith('customers')) {
            this._ngZone.run(() => this._router.navigateByUrl('/', { replaceUrl: true }));
          }
        });

      if (readySource && readySource.toLowerCase() !== 'dom') {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
        // TODO: enable background mode (Apple Error => ‘declares support for audio in the UIBackgroundModes key in your Info.plist.’)
        // get permission for Badge
        this._badge
          .hasPermission()
          .then(hasPermission => {
            if (!hasPermission) {
              return this._badge.requestPermission();
            }
          })
          .catch(err => {
            console.error(err);
          });

        /**
         * Push Notifications
         */
        // refresh FCM token
        this.fcm
          .onTokenRefresh()
          .pipe(
            switchMap(token => {
              console.log(token);
              return this._http.put(API.tokenApi, {
                token
              });
            })
          )
          .subscribe();
        // subscribe to coming notifications
        this.fcm.onNotification().subscribe(data => {
          console.log(data);
          if (data.wasTapped) {
            console.log('Received in background\n', data);
            // this.router.navigate([data.landing_page, data.price]);
          } else {
            console.log('Received in foreground\n', data);
            // this.router.navigate([data.landing_page, data.price]);
          }
        });
      }
      /**
       * Authentication
       */
      this._authServ.isLoggedIn().subscribe(isLoggedIn => {
        if (isLoggedIn) {
          this._ngZone.run(() => this._router.navigateByUrl('/', { replaceUrl: true }));
          // Initialize chat communication
          // NOTICE: The Auth Service is going to get the token first then you can connect the socket
          // after wards you can use it in both network and chat services
          this._socketServ.connect(this._authServ.accessToken);
          this._socketServ.socket.on('logout', () => {
            this._authServ.logout();
          });
        } else {
          // this._authServ.logout();
          // close the scoket (disconnect manually)
          console.log('closing socket');
          this._socketServ.socket && this._socketServ.socket.close();
          this._ngZone.run(() => this._router.navigateByUrl('/auth', { replaceUrl: true }));
        }
      });
    });
  }
}

// // TODO: recheck if we need this => this is for the error "Could not find cordova.js script tag. Plugin loading may fail" on android
// /**
//  * @see https://github.com/ionic-team/ionic-conference-app/issues/668
//  */
// function findCordovaPath() {
//   let path = null;
//   const scripts = document.getElementsByTagName('script');
//   const startterm = '/cordova.';
//   let term = '/cordova.js';
//   for (let n = scripts.length - 1; n > -1; n--) {
//     const src = scripts[n].src.replace(/?.*$/, ''); // Strip any query param (CB-6007).
//     const idx = src.indexOf(startterm);
//     if (idx >= 0) {
//       term = src.substring(idx + 1);
//     }
//     if (src.indexOf(term) === (src.length - term.length)) {
//       path = src.substring(0, src.length - term.length) + '/';
//       break;
//     }
//   }
//   return path;
// }
