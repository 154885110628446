import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  readonly info: {
    isCordova: boolean;
    width: number;
  } = {
      isCordova: null,
      width: null
    };

  constructor() { }
}
