import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from, of, ReplaySubject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { tap, switchMap, retry, catchError } from 'rxjs/operators';
import { API, ACCESS_TOKEN_KEY, USER_INFO_KEY } from '../../../environments/environment';
import { ToastController, Platform } from '@ionic/angular';
import { Recipient } from '../../models/chat.model';
import { Storage } from '@ionic/storage';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FCM } from '@ionic-native/fcm/ngx';
import { PlatformService } from '../platform/platform.service';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _isLoggedIn$: ReplaySubject<boolean> = new ReplaySubject(1);
  private _accessToken: string;
  private _user: Recipient;

  get accessToken(): string {
    return this._accessToken;
  }

  get userInfo() {
    return this._user;
  }
  constructor(
    private _http: HttpClient,
    private _toastCtrl: ToastController,
    private _jwtHelper: JwtHelperService,
    private _storage: Storage,
    private _platform: Platform,
    private _platformServ: PlatformService,
    private _fcm: FCM
  ) {
    this._platform.ready().then((val) => {
      this._checkToken();
    });
  }


  public isLoggedIn(): Observable<boolean> {
    return this._isLoggedIn$.asObservable();
  }


  private async _checkToken() {
    const accessToken = await this._storage.get(ACCESS_TOKEN_KEY);
    if (accessToken) {
      // const decoded = this._jwtHelper.decodeToken(accessToken);
      const userInfo = await this._storage.get(USER_INFO_KEY);
      const isExpired = this._jwtHelper.isTokenExpired(accessToken);
      console.log('is token expired? ', isExpired);
      if (!isExpired) {
        // TODO: Review this
        // this._user = decoded;
        userInfo ? (this._user = JSON.parse(userInfo)) : this.logout();
        // set token
        this._accessToken = accessToken;
        // notifiy the app of the auth state
        this._isLoggedIn$.next(true);
        return;
      }
    }
    console.log('removing tokens');
    this.logout();
  }

  login(credentialsForm: NgForm): Observable<any> {
    console.log('logging in');
    return this._http.post(API.login, credentialsForm).pipe(tap((res: {
      user: {
        id: string,
        // email: user.email,
        firstName: string,
        lastName: string,
        image_url: string,
        // telephone: user.telephone,
        user_type: 'admin' | 'superAdmin' | 'recipient'
      }, token: string
    }) => {
      if (res) {
        console.log('res:\n', res);
        // set the jwt token and the user info
        this._storage.set(ACCESS_TOKEN_KEY, res.token);
        this._accessToken = res.token;
        // set user info
        this._user = new Recipient(res.user.id, `${res.user.firstName} ${res.user.lastName}`, res.user.user_type, res.user.image_url);
        // save user info in db
        this._storage.set(USER_INFO_KEY, JSON.stringify(this._user));
        // Send FCM token for mobile PUSH notifications
        if (this._platformServ.info.isCordova) {
          console.log('fcm: ', this._fcm);
          from(this._fcm.getToken()).pipe(switchMap((token) => {
            console.log(token);
            return this._http.put(API.tokenApi, {
              token
            }).pipe(retry(5), catchError(err => {
              console.error(err);
              // if (err.status === 404) {
              //   this.logout();
              // }
              this.logout();
              return of(null);
            }));
          })).subscribe();
        }
        // notifiy the app of the auth state
        this._isLoggedIn$.next(true);

        // show a welcoming message
        this._toastCtrl.create({
          message: 'Welcome to MyDiningBot',
          duration: 2000,
          keyboardClose: true
        }).then((toast) => {
          toast.present();
        });
      }
    }));
    // return from(new Promise(resolve => {
    //   setTimeout(() => resolve(this._isLoggedIn = true));
    // }));
  }

  async logout() {
    console.log('logging out');
    this._isLoggedIn$.next(false);
    this._accessToken = null;
    // remove JWT Token
    await this._storage.remove(ACCESS_TOKEN_KEY);
    // remove user info
    await this._storage.remove(USER_INFO_KEY);
  }


  register() {
    // TODO: specify url in environment
    window.open('https://mydiningbot.com/', '_system');
    return false;
  }
}
