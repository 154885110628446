import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, } from '@angular/router';
import { AuthGuardAct } from './guards/auth/auth.guard';
import { NotAuthGuardLoad, NotAuthGuardAct } from './guards/not-auth/not-auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'tabs', pathMatch: 'full' },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
    // TODO: Notice: Implementing canLoad with AuthGuard could result in slow app
    canActivate: [AuthGuardAct]
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule),
    canLoad: [NotAuthGuardLoad],
    canActivate: [NotAuthGuardAct],
  },
  // { path: 'about', loadChildren: './pages/about/about.module#AboutPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
