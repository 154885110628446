// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
};

export const BASE_URL = 'https://dev.chat.admin.mydiningbot.com';

export const API = {
  login: BASE_URL + '/auth/login',
  tokenApi: BASE_URL + '/api/token',
  messagesApi: BASE_URL + '/api/messages',
  uploadApi: BASE_URL + '/api/upload',
  closeSessionsApi: BASE_URL + '/api/sessions/close'
};

export const ACCESS_TOKEN_KEY = 'access_token';
export const USER_INFO_KEY = 'user_info';


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
